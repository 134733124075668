@import "./_vendor-flatpickr-vars";

$calendarBackground: #fff;
$calendarBorderColor: #eee;

$monthForeground: #3c3f40;
$monthBackground: transparent;

$weekdaysBackground: transparent;
$weekdays_color: #757575;

$dayForeground: #404848;
$dayHoverBackground: #e9e9e9;
$disabled_border_color: $dayHoverBackground;

$disabled_day_color: rgba(black, 0.15);

$todayColor: #F64747;
$today_fg_color: #fff;

$selectedDayBackground: #4f99ff;
$selectedDayForeground: #fff;

.flatpickr-calendar {
  width: $daysWidth;
}

.dayContainer {
  padding: 0;
  border-right: 0;
}

span.flatpickr-day {
  &, &.prevMonthDay, &.nextMonthDay {
    border-radius: 0 !important;
    border: 1px solid $dayHoverBackground;
    max-width: none;
    border-right-color: transparent;

    &:nth-child(n+8) {
      border-top-color: transparent;
    }

    &:nth-child(7n-6) {
      border-left: 0;
    }

    &:nth-child(n+36) {
      border-bottom: 0;
    }

    &:nth-child(-n+7) {
      margin-top: 0;
    }

    &.today:not(.selected) {
      border-color: $dayHoverBackground;
      border-right-color: transparent;
      border-top-color: transparent;
      border-bottom-color: $todayColor;

      &:hover {
        border: 1px solid $todayColor;
      }
    }

    &.startRange, &.endRange {
      border-color: $selectedDayBackground;
    }


    &.today, &.selected {
      z-index: 2;
    }
  }
}

.rangeMode .flatpickr-day {
  margin-top: -1px;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  box-shadow: none;
}

.flatpickr-weekwrapper span.flatpickr-day {
  border: 0;
  margin: -1px 0 0 -1px;
}


.hasWeeks .flatpickr-days {
  border-right: 0;
}
